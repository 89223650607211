@import "./variables";
@import "./typography";
@import "./common";
@import "@angular/cdk/overlay-prebuilt.css";
@import "~bootstrap/dist/css/bootstrap.css";

@font-face {
  font-family: "Publico";
  src: url("./fonts/Publico/PublicoTextApp-Roman.ttf");
}

@font-face {
  font-family: "Publico-Bold";
  src: url("./fonts/Publico/PublicoTextApp-Bold.ttf");
  font-weight: bold;
}

body {
  /*padding-top: 60px;*/
  background: #ffffff;
  /*background: #DFE5E6;*/
  /* padding-bottom: 20px; */
  font-family: "Publico", "Georgia";
  font-size: 14px;
}

//https://stackoverflow.com/questions/45199319/angular-4-scroll-animation
html {
  scroll-behavior: smooth;
}

p {
  font-size: 14px;
}

.wrapper {
  padding-top: 75px;
}

// dropdown

.header-nav > li:first-child {
  padding-left: 0;
}

.header-nav-profile .dropdown {
  padding: 0;
}

.header-nav > li {
  position: relative;
  display: block;
  padding: 14px 0 14px 12px;
  float: left;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.header-nav {
  margin: 0 12px;
  padding-left: 0;
  list-style: none;
}

ul {
  list-style-type: disc;
}

.header-nav-profile .dropdown.open {
  color: #313534;
}

.header-nav > li:first-child {
  padding-left: 0;
}

.header-nav-profile .dropdown {
  padding: 0;
}

.header-nav > li {
  position: relative;
  display: block;
  padding: 14px 0 14px 12px;
  float: left;
}

.dropdown-menu > li > a {
  line-height: 1.828571;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu > li > a {
  text-align: left;
}

a {
  border: none !important;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a {
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  text-align: -webkit-match-parent;
}

.cursorPointer {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.no-scroll-x {
  max-width: 100%;
  overflow-x: hidden;
}

//Menu header
body {
  background-color: white;
}

.ulheader {
  list-style-type: none;
  margin: 0;
  z-index: 2; //higher than content wrapper
  padding: 0;
  overflow: hidden;
  background-color: red;
  margin-top: 4rem;
  position: fixed;
  width: 100%;
}

//only for header list, not kendo-multiselect list
.ulheader li {
  float: left;
}

li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
}

li a:hover,
.dropdown:hover .dropbtn {
  color: black;
}

li.dropdown {
  display: inline-block;
}

li.dropdown a:hover {
  // background-color: #AFAFAF;
  // color: black
}

.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #150f96;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
  position: fixed !important;
}

//Grid
.customer-photo {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: 32px 35px;
  background-position: center center;
  vertical-align: middle;
  line-height: 32px;
  box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}

.customer-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  padding-left: 10px;
}

.red {
  color: #d9534f;
}

.text-bold {
  font-weight: 600;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.k-menu:not(.k-context-menu) {
  border-color: #dee2e6;
  color: #212529;
  background-color: #f1352b;
}

.k-menu-item {
  border-right: white solid 5px;
}

.k-menu-link {
  color: #fff !important;
  padding: 0rem 0.5rem;
  font-family: "Publico", "Georgia"; //todo: dist override
}

.k-dropdown,
.k-dropdowntree {
  display: block;
  width: 100%;
}

//only those under .form-group, since <kendo-pager/> affected too
.form-group .k-dropdown .k-select {
  background-color: #fff;
}

//hack for v14
//only those under .form-group, since <kendo-pager/> affected too
//this is almost similar to .form-control
.form-group kendo-dropdownlist,
kendo-combobox {
  //display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //border: 1px solid #ced4da;
  //border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//hack for v14
kendo-dropdownlist.k-button.k-state-active,
kendo-dropdownlist.k-button:active {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
  background-image: none;
  background-image: none, linear-gradient(to bottom, false);
}

.k-dropdown,
.k-dropdowntree {
  display: block;
  width: 100%;
}

//hack for v14
//this is almost similar to .form-control
.k-multiselect {
  //display: block;
  width: 100%;
  //height: calc(1.5em + 0.75rem + 2px);//cannot set default height, esp multi-select
  padding: 0.175rem 0.375rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.k-multiselect .k-button {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
}

.k-multiselect-wrap li {
  border-radius: 1.071em;
  line-height: 1.86em;
  padding: 0 1.6em 0 0.857em;
}

kendo-taglist .k-chip {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
}

//hack for v14
kendo-datepicker,
kendo-dateinput {
  /* display: block; */
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.k-datepicker,
.k-timepicker {
  width: 100%;
}

.k-dialog-buttongroup {
  border-color: white;
}

.modal-footer {
  border-top: none;
}

// Header
.material-bg .k-input,
.material-bg .k-dropdown-wrap .k-i-arrow-s {
  color: white;
}

.material-bg .k-dropdown-wrap {
  border-color: white;
}

.material-bg .k-dropdown-wrap.k-state-focused {
  border-color: #697bcf;
  border-bottom-width: 2px;
}

.material-bg .k-dropdown-wrap:hover {
  border-color: #2942bb;
  border-bottom-width: 2px;
}

.ddl-theme {
  width: 60px;
}

.ddl-locale .k-dropdown-wrap {
  width: 98px;
}

.k-tabstrip div[role="tabpanel"] {
  //unset the z-index, so that overlay is not clipped
  z-index: unset;
}

//remove padding if tab under mf-sg-tabs, other place still needs padding
#mf-sg-tabs .k-tabstrip > .k-content {
  padding: 0;
}

//https://github.com/telerik/kendo-themes/issues/2909
.k-tabstrip-items-wrapper {
  z-index: 1;
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 1px;
  margin-bottom: 0px;
}

#main .k-expander-header {
  background-color: red !important;
  min-height: 0px !important;
  height: 30px;
  min-height: 30px;
}

.k-expander-header {
  background-color: #ffb617 !important;
  min-height: 0px !important;
  height: 30px;
  min-height: 30px;
}

.k-expander-content {
  padding: 0.5rem 0.5rem;
}

.k-expander-title {
  color: #fff !important;
  font-family: "Publico-Bold";
  text-transform: capitalize;
}

.k-dialog-buttongroup {
  display: block;
}

.k-dialog-buttongroup > button {
  float: right;
}

.modal-title-red .k-dialog-titlebar {
  background-color: #f1352b !important;
}

.modal-title-orange .k-dialog-titlebar {
  background-color: #ff6600 !important;
}

.modal-title-light-orange .k-dialog-titlebar {
  background-color: #ffb617 !important;
}

.modal-title-green .k-dialog-titlebar {
  background-color: #7acb00 !important;
}

.modal-footer-border-top {
  border-top: #afafaf solid thin;
}

#mdProductSelectionDialog .k-dialog-titlebar .k-window-title {
  color: white !important;
  //text-align: center;
  display: unset; //revert the inherited flex
}

//do not need action button for mdProductSelectionDialog
#mdProductSelectionDialog
  > div.k-window.k-dialog
  > kendo-dialog-titlebar
  > div.k-window-actions.k-dialog-actions {
  display: none;
}

.k-window-content,
.k-prompt-container {
  overflow-x: hidden;
}

#mdConfirmationMessageV4 .k-window-content,
.k-prompt-container {
  overflow-y: hidden;
}

.container-overflow-y {
  height: 300px;
  overflow-y: scroll;
}

//kendo-dialog animation; apply to all, if has both classes
.flex-end-justified.k-dialog-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

//kendo-dialog animation; apply to all, if direct child of specified class then only apply height
.flex-end-justified > .k-dialog {
  height: 100%;
}

.btn {
  text-transform: none !important;
  border-radius: 0px !important;
  padding: 10px 12px;
  font-size: 15px;
  margin-left: 0.5rem;
}

.k-button {
  border: none;
  border-radius: 0;
  border-color: #9da2a4;
  color: #9da2a4;
  background-color: #fff;
  background-position: 50% 50%;
  background-image: none;
  background-image: none,
    -webkit-gradient(linear, left top, left bottom, from(false));
  background-image: none, -webkit-linear-gradient(top, false);
  background-image: none, linear-gradient(to bottom, false);
}

.k-button.k-state-active,
.k-button:active {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
  background-image: none;
  background-image: none,
    -webkit-gradient(linear, left top, left bottom, from(false));
  background-image: none, -webkit-linear-gradient(top, false);
  background-image: none, linear-gradient(to bottom, false);
}

kendo-fileselect .k-button-solid {
  border-style: solid;
  text-transform: uppercase;
}

.k-rounded-md {
  border-radius: 3px;
}

.modal-title-custom {
  margin: 0;
  line-height: 1.42857143;
  font-family: "Publico", bold;
  color: #ffffff;
  font-size: 22px;
  height: 55px;
  vertical-align: middle;
  top: 0px;
  padding-top: 11px;
}

// .k-window-content,
// .k-prompt-container {
//   padding: 0rem !important;
// }

.k-dialog {
  box-shadow: none;
  border-radius: 0px !important;
}

h3 {
  background-color: #84bd00;
  font-family: "Publico", bold;
  color: #ffffff;
  font-size: 15px;
  height: 25px;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  top: 0px;
}

.modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px !important;
}

.k-form-field-wrap {
  flex: 0 1 auto !important;
}

.k-icon {
  color: #32364c;
}

.k-i-filter {
  color: #9da2a4 !important;
}

.k-grid tr {
  line-height: 2.4em;
}

//apply to those under form-group only, else label in kendogridcell also affected
.form-group label {
  font-weight: bold;
}

.k-datepicker .k-select {
  border: 1px solid #d7d5d5;
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: white;
}

.k-datepicker > .k-picker-wrap {
  border: none;
}

//this is almost similar to .form-control, except border-radius
.k-datepicker .k-dateinput .k-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  //border-radius: 0.25rem;
  border-radius: 0.25rem 0 0 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.k-window-content,
.k-prompt-container {
  padding: 0px 0px !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

//cdk overlay related
.overlay-backdrop {
  background-color: #00000080;
}

.overlay-panel {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // padding: 20px;
}

.modal-body-auto {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

hr {
  width: 100%; //without this, some won't show
}

.header {
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
}

.k-grid-toolbar .k-button {
  color: #ff4350;
}

.k-grid-content .k-button-icontext {
  color: #fff;
  background-color: #ff4350;
  border-color: #ff4350;
}

.k-button.k-grid-edit-command {
  color: #ff4350;
}

.k-button.k-grid-cancel-command {
  color: #ff4350;
}

.k-button.k-grid-remove-command {
  color: #ff4350;
}
kendo-formerror {
  color: #ffc147;
  background-color: #ffecc7;
  border-color: #ffecc7;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding: 11px 20px 9px;
}
.k-form-error,
.k-text-error {
  color: #ffc147;
}
.k-form-hint,
.k-form-error {
  margin-top: 0px;
  display: flex;
  font-size: 14px;
  font-style: normal;
}
.k-icon-custom {
  color: #ffc147 !important;
  font-size: 16px;
  font-family: "WebComponentsIcons";
  line-height: 1;
  text-decoration: none;
  flex-shrink: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding-right: 10px;
}

.k-numerictextbox {
  display: inline-block;
  width: 12.4em;
}

.customMarginRadio {
  margin: -5px 0 0 10px;
}

.form-control {
  font-size: 14px;
}
